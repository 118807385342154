
.section {
    position: relative;
}
.process-list {
    > [class*="col"] {
        .process-card {
            padding: 40px 16px;
            height: 100%;
            border-radius: 16px;
            max-width: 400px;
            margin: 0 auto;
            word-break: keep-all;
        }
        &:not(:last-child) {
            .process-card {
                position: relative;
                &::after {
                    content: "";
                    position: absolute;
                    right: 50%;
                    bottom: -28px;
                    transform: translateX(50%) rotate(90deg);
                    width: 24px;
                    height: 24px;
                    background: url(/images/guide/temporary/process-arrow.svg) no-repeat center;
                }
            }
        }
    }
}
.bottom-banner {
    aspect-ratio: 16/9;
    display: flex;
    align-items: center;
    padding: 20px 0;
    &__title {
        font-size: 28px;
        font-weight: 500;
        > p {
            line-height: 1;
            padding: 4px 0;
        }
        .point {
            font-size: 50px;
        }
        .primary {
            padding-top: 4px;
        }
    }
}
@media (min-width: 768px) {
    .process-list {
        > [class*="col"] {
            &:not(:last-child) {
                .process-card {
                    position: relative;
                    &::after {
                        content: "";
                        position: absolute;
                        right: -32px;
                        bottom: 50%;
                        transform: translatey(50%);
                        width: 24px;
                        height: 24px;
                        background: url(/images/guide/temporary/process-arrow.svg) no-repeat center;
                    }
                }
            }
        }
    }
    .bottom-banner {
        aspect-ratio: 1920/440;
        display: flex;
        align-items: center;
        &__title {
            font-size: 36px;
            .point {
                font-size: 70px;
            }
            .primary {
                padding-top: 8px;
            }
        }
    }
}
